<template>
  <dv-full-screen-container class="bj" style="width: 100%; height: 100%">
    <dv-border-box-8 style="background: rgba(22, 40, 80, 0.8)">
      <van-overlay :show="showoverlay" @click="showoverlay = false">
        <dv-loading>
          <div style="color: #0dc4f4">加载中...</div>
        </dv-loading>
      </van-overlay>

      <van-popup v-model:show="popupshow" round>
        <dv-border-box-1 style="">
          <div class="popup">
            <div class="popuptitle">是否允许播放声音？</div>
            <div class="popupfooter">
              <div @click="audio">拒绝</div>
              <div @click="audio">允许</div>
            </div>
          </div>
        </dv-border-box-1>
      </van-popup>

      <Video :src="videosrc" v-show="videoshow" @videoshow="videoshows">
        <template v-slot:title>{{ title }} 摄像机</template>
        <template v-slot:footer>
          <!-- <van-swipe class="swipes" @change="onChange">
          <van-swipe-item v-for="(item, index) in list" :key="index">{{
            item.name
          }}</van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">
              {{ actives + 1 }}/{{ list.length }}
            </div>
          </template>
        </van-swipe> -->
        </template>
      </Video>

      <van-row type="flex" style="height: 100%; padding: 10px">
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <img :src="data.factory?.avatar" class="logo" v-if="data.factory?.avatar" />
              <img src="../assets/slogan.png" class="logos" v-if="!data.factory?.avatar" />
              <!-- <img
                src="https://fdfs.singran.vip/group1/M00/92/B8/rBEAA2NHiuiABQgIAAINMXZfrd4747.png"
                class="logos" 
              /> -->
              <div v-if="data.factory?.name">
                {{ data.factory?.name }}
              </div>
              <!-- <div>四川林易洗涤服务有限公司</div> -->
            </div>
            <div class="infotime">{{ ondate }}</div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtitle">芯任卫生联防联控云平台</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div v-if="device?.model">设备型号：{{ device?.model }}</div>
            <div v-if="device_code">设备编号：{{ device_code }}</div>
          </div>
        </van-col>
        <van-col span="7" style="height: 90%">
          <div style="height: 40%; position: relative">
            <dv-border-box-5 class="left" style="padding: 20px 15px">
              <div class="centerright">
                <div>
                  <span class="numbers">{{ data.summary?.count || 0 }}</span>
                  <span style="font-size: 18px">批</span>
                </div>
                <div class="rem">近一天清洁批次</div>
              </div>
            </dv-border-box-5>
            <dv-border-box-5 :reverse="true" class="right" style="padding: 20px 15px">
              <div class="center">
                <div>
                  <span class="numbers">{{
                    data.summary?.total_weight || 0
                  }}</span>
                  <span style="font-size: 18px">吨</span>
                </div>

                <div class="rem">近一天清洁重量</div>
              </div>
            </dv-border-box-5>
            <div class="left_bottom">
              <div>清洁布草</div>
              <dv-decoration-3 class="yangshi" />
            </div>
            <div class="reight_top">
              <div>清洁任务</div>
              <dv-decoration-3 class="yangshi" />
            </div>
          </div>
          <div style="height: 38%">
            <div class="left_bottom_ban">
              <div style="width: 50%">
                <div class="left_title">
                  <div>平均满仓率</div>
                  <div style="font-size: 8px">实际装载量/额定装载量</div>
                </div>

                <div class="borders">
                  <dv-water-level-pond :config="fully" class="yuan" />
                </div>
                <div style="margin-top: 12px">
                  <span class="left_num">{{
                    data.summary?.avg_weight || 0
                  }}</span>
                  <span class="left_fu">公斤/仓</span>
                </div>
                <div style="font-size: 10px; color: #fff; height: 25px">
                  <!-- 平均仓位重量/公斤 -->
                </div>
              </div>
              <div style="width: 50%">
                <div>
                  <div class="borders">
                    <div class="zong">
                      <div class="zongs">
                        <div>{{ zong }}</div>
                        <div style="font-size: 10px">L</div>
                      </div>
                      <div style="font-size: 10px; color: #808080">
                        清洁剂总用量
                      </div>
                    </div>
                    <dv-active-ring-chart :config="wash" class="huan" />
                  </div>
                  <div class="left_pin">
                    <div style="font-size: 10px; color: #fff; margin-bottom: 5px">
                      <div style="margin-top: 5px">清洁剂</div>
                      <div style="margin-top: 5px">品 牌</div>
                    </div>
                    <img src="../assets/xidilogo.png" alt="" style="width: 100px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 30%">
            <div style="color: #fff">
              <div class="titleri">近一天清洁重量趋势分析</div>
              <div id="chart" class="chart"></div>
            </div>
          </div>
        </van-col>
        <van-col span="5" style="height: 90%">
          <dv-border-box-6>
            <div style="padding: 10px">
              <div style="
                  font-size: 15px;
                  color: #06b9f9;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <img src="../assets/logo.svg" alt="" style="width: 90px" />
                <span>JENSEN隧道式洗衣龙</span>
                <span :style="{ color: state.color || 'red' }">
                  [{{ state.name || "休息中" }}]</span>
              </div>
              <van-cell-group v-for="(item, index) in data.ships" :key="index">
                <van-cell>
                  <template #title>
                    <div style="color: #0499d1; display: flex; align-items: center">
                      <div class="cangwei">{{ item.count ? item.count + 1 : index + 1 }}仓</div>
                      <!-- <van-loading
                        color="#39e4cd"
                        style="width: 20px; height: 40px"
                      /> -->
                      <div v-if="state.name == '生产中'" class="avatar">{{ item.name }}</div>
                      <div v-if="state.name == '生产中'" class="cangtime">{{ item.customer_name }}</div>
                    </div>
                  </template>
                  <template #default>
                    <div style="display: flex; flex-direction: column">
                      <div v-if="item.status" class="gongjin">
                        <div>{{ item.weight }}</div>
                        <div style="font-size: 15px">公斤</div>
                      </div>
                      <div v-else class="gongjin" style="color: red;">
                        空仓
                      </div>
                    </div>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
          </dv-border-box-6>
        </van-col>
        <van-col span="12" style="height: 90%">
          <div style="height: 70%">
            <van-row span="24" style="height: 100%; width: 100%">
              <van-col span="5" style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-around;
                  height: 100%;
                ">
                <van-circle v-model:current-rate="currentRate_temp" :rate="rate.temp" layer-color="#224590" :color="color.temp_color" class="cards">
                  <template #default>
                    <div class="card">
                      <div class="num">
                        <div class="nums" :style="{ color: color.temp_color }">
                          <countTo :startVal="uncounts.temp" :endVal="Number(charts.temp) || 0" :duration="3000" :decimals="1"></countTo>
                          <span style="font-size: 12px; color: #fff">℃</span>
                        </div>

                        <div class="reamk">清洁温度</div>
                      </div>
                    </div>
                  </template>
                </van-circle>
                <van-circle v-model:current-rate="currentRate_ph" :rate="rate.ph" layer-color="#224590" :color="color.ph_color" class="cards">
                  <template #default>
                    <div class="card">
                      <div class="num">
                        <div class="nums" :style="{ color: color.ph_color }">
                          <countTo :startVal="uncounts.ph" :endVal="Number(charts.ph) || 0" :duration="3000" :decimals="2"></countTo>
                          <span style="font-size: 12px; color: #fff">pH</span>
                        </div>
                        <div class="reamk">中和pH值</div>
                      </div>
                    </div>
                  </template>
                </van-circle>
                <van-circle v-model:current-rate="currentRate_hd" :rate="rate.hd" :color="color.hd_color" layer-color="#224590" class="cards">
                  <template #default>
                    <div class="card">
                      <div class="num">
                        <div class="nums" :style="{ color: color.hd_color }">
                          <countTo :startVal="uncounts.hd" :endVal="Number(charts.hd) || 0" :duration="3000" :decimals="2"></countTo>
                          <span style="font-size: 12px; color: #fff">mg/L</span>
                        </div>
                        <div class="reamk">水硬度值</div>
                      </div>
                    </div>
                  </template>
                </van-circle>
              </van-col>
              <van-col span="13" class="vessels">
                <div id="temp" class="vessel"></div>
                <div id="ph" class="vessel"></div>
                <div id="hd" class="vessel"></div>
              </van-col>
              <van-col span="6">
                <div class="cctv">
                  <div class="cctvimg" v-for="(item, index) in charts.cctv" :key="index" @click="videohttp(index)">
                    <div class="cctvtitle">
                      {{ item.stage_names[0] }} {{ item.minute }}
                    </div>
                    <img :src="item.snapshot" alt="" class="imgs" v-if="item.snapshot" />
                    <img src="../assets/video.png" alt="" class="imgs" v-if="!item.snapshot" />
                  </div>
                </div>
              </van-col>
            </van-row>
          </div>
          <div style="height: 30%; display: flex">
            <div style="width: 33.34%">
              <dv-border-box-6>
                <div style="padding: 10px; height: 100%; color: #fff">
                  <div style="padding: 0px; height: 97%">
                    <div style="height: 14%" class="title">清洁客户</div>
                    <dv-scroll-ranking-board :config="top" style="height: 78%" />
                  </div>
                </div>
              </dv-border-box-6>
            </div>
            <div style="width: 33.34%">
              <dv-border-box-6>
                <div style="padding: 10px; height: 100%; color: #fff">
                  <div style="height: 14%" class="title">清洁程序分布</div>
                  <div id="clean" class="clean"></div>
                </div>
              </dv-border-box-6>
            </div>
            <div style="width: 33.34%">
              <dv-border-box-6>
                <div style="padding: 10px; height: 100%; color: #fff">
                  <div style="height: 14%" class="title">水电气</div>
                  <div id="utilities" class="utilities"></div>
                </div>
              </dv-border-box-6>
            </div>
          </div>
        </van-col>
      </van-row>
    </dv-border-box-8>
  </dv-full-screen-container>
</template>

<script>
import {
  Col,
  Row,
  Tab,
  Tabs,
  Icon,
  List,
  Cell,
  CellGroup,
  PullRefresh,
  Button,
  Card,
  Popup,
  Tag,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Notify,
  Overlay,
  Loading,
  CountDown,
  Circle,
} from "vant";
import api from "../utils/api";
import countTo from "./vue-countTo.vue";
import Video from "./video.vue";
import store from "../store/index";
export default {
  name: "home",
  props: {},
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Card.name]: Card,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Notify.name]: Notify,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [CountDown.name]: CountDown,
    [Circle.name]: Circle,
    Video,
    countTo,
  },
  data() {
    return {
      num: "",
      popupshow: false,
      showoverlay: false,
      loading: false,
      isLoading: false,
      finished: false,
      videoshow: false,
      intervalId: null,
      intervalIds: null,
      lineNumber: 3,
      data: {},
      charts: {},
      uncounts: {},
      mtopic: "",
      videosrc: "",
      date: "",
      ondate: "",
      title: "",
      dur: 0,
      msg: {},
      xin: {},
      uncount: 0,
      active: 0,
      factory_code: "",
      device_code: "",
      factory: {},
      device: {},
      history_list: [],
      day_list: [],
      detail_list: [],
      service_list: {},
      threshold: {},
      bjcolor: "#1989FA",
      zccolor: "#1989FA",
      color: {},
      backtime: 120000,
      datatime: 5000,
      datatimes: 20000,
      rate: {},
      currentRate_hd: 0,
      currentRate_temp: 0,
      currentRate_ph: 0,
      door_scene: "",
      dorr_count: "",
      batch_code: "",
      zong: "",
      fully: {
        shape: "round",
      },
      top: {
        rowNum: 5,
      },
      wash: {
        lineWidth: 10,
        radius: 63,
        digitalFlopStyle: {
          fontSize: 12,
        },
        showOriginValue: true,
      },
      daychart: {
        date: [],
        we: [],
      },
      cost: {},
      state: "",
    };
  },
  mounted() {
    window.addEventListener("resize", this.renderResize, false);
    this.num = store.state.edition;
    if (this.$route.query.device_code) {
      this.device_code = this.$route.query.device_code;
      // this.popupshow = true;
      this.time();
      this.factorydevicewashsummary();
    } else {
      Notify({ message: "请输入工厂编码和设备编码", duration: 50000 });
    }
    let baseUrl = "";
    if (process.env.NODE_ENV == "development") {
      baseUrl = process.env.VUE_APP_DEVURL;
    } else if (process.env.NODE_ENV == "production") {
      baseUrl = process.env.VUE_APP_TESTURL;
    } else {
      baseUrl = "";
    }
    console.log(baseUrl);
  },
  created() {
    console.log("created");
    // this.apptonelist();
    this.dataRefreh();
  },
  unmounted() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.clears();
  },
  watch: {
    charts: function (newVal, endVal) {
      console.log("新", newVal, "旧", endVal);
      if (endVal.temp && newVal.temp != endVal.temp) {
        this.uncounts.temp = endVal.temp;
      }
      if (endVal.ph && newVal.ph != endVal.ph) {
        this.uncounts.ph = endVal.ph;
      }
      if (endVal.hd && newVal.hd != endVal.hd) {
        this.uncounts.hd = endVal.hd;
      }
    },
    msg: function (newVal, endVal) {
      if (endVal.count && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal.count;
          if (newVal.records) {
            newVal = newVal.records.map((i, e) => {
              i.uncount = endVal.records[e].count;
            });
          }
        } else {
          this.uncount == 0;
          newVal = newVal.records.map((i) => {
            i.uncount = 0;
          });
        }
      }
    },
  },
  methods: {
    audio() {
      console.log("允许");
      this.popupshow = false;
      const audio = new Audio();
      audio.src = "http://d.singran.vip/assets/audio/di.mp3";
      audio.play();
    },
    time() {
      const date = new Date();
      let seperator1 = "-";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      let hours = date.getHours(); //获取当前小时（0-23）
      let minutes = date.getMinutes();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let currentdate = year + seperator1 + month + seperator1 + strDate;
      let oncurrentdate =
        year +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        hours +
        ":" +
        minutes;
      this.ondate = oncurrentdate;
      this.date = currentdate;
    },
    factorydevicewashsummary() {
      api
        .factorydevicewashsummary({
          device_code: this.device_code,
          // "1d584f175fe0d751",
          date: this.date,
        })
        .then((res) => {
          console.log("概要数据", res);
          if (res.data.data.work_status === 0) {
            this.state = {
              name: "休息中",
              color: "red",
            };
          } else {
            console.log("生产中");
            this.state = {
              name: "生产中",
              color: "#66FD00",
            };
          }
          res.data.data.summary.total_weight = (
            res.data.data.summary.total_weight / 1000
          ).toFixed(2);
          res.data.data.summary.avg_weight =
            res.data.data.summary.avg_weight.toFixed(2);
          res.data.data.customer_top.map((i) => {
            i.value = i.weight;
            i.customer_name = "客户" + i.customer_code
          });
          res.data.data.detergents_summary.map((i) => {
            i.value = i.val / 1000;
          });
          const arr = res.data.data.detergents_summary.map((i) => {
            return i.val / 1000;
          });
          const value = arr.reduce((n, m) => n + m);
          res.data.data.programs_chart.map((i) => {
            i.value = i.count;
          });
          res.data.data.wash_techno_chart.map((i) => {
            i.value = i.count;
          });
          res.data.data.ships.map((i) => {
            i.minute = i?.minute ? i.minute.split(" ")[1] : null;
            i.name = i?.customer_name ? i.customer_name.split("")[0] : null;
            i.weight = i?.weight ? i.weight.toFixed(1) : null;
          });
          res.data.data.weight_chart.map((e, i) => {
            this.daychart.date[i] =
              res.data.data.weight_chart[i]?.hour.split(" ")[1];
            this.daychart.we[i] = res.data.data.weight_chart[i].weight;
          });
          this.data = res.data?.data;
          this.wash.data = res.data?.data.detergents_summary;
          this.wash = { ...this.wash };
          this.zong = value.toFixed(1);
          this.fully.data = [Number(res.data?.data.summary.full_ship_rate)];
          this.fully = { ...this.fully };
          this.top.data = res.data?.data.customer_top;
          this.top = { ...this.top };

          this.factoryindicatorsdata();
          this.factorydevicewashindicator();
          this.factorydevicewashcost();
          this.$nextTick(() => {
            setTimeout(() => {
              this.initchart();
              this.initclean();
            });
          });
        });
    },
    factorydevicewashindicator() {
      // "1d584f175fe0d751"
      api
        .factorydevicewashindicator({ device_code: this.device_code })
        .then((res) => {
          console.log("图", res);
          console.log("图表", res.data?.data);
          this.charts = res.data?.data;
          console.log(this.threshold);
          this.threshold?.hardness_threshold.map((i) => {
            if (
              Number(i.from) <= this.charts.hd &&
              this.charts.hd <= Number(i.to)
            ) {
              this.color.hd_color = i.color;
            }
          });
          this.threshold?.phs_threshold.map((i) => {
            if (
              Number(i.from) <= this.charts.ph &&
              this.charts.ph <= Number(i.to)
            ) {
              this.color.ph_color = i.color;
            }
          });
          this.threshold?.temps_threshold.map((i) => {
            if (
              Number(i.from) <= this.charts.temp &&
              this.charts.temp <= Number(i.to)
            ) {
              this.color.temp_color = i.color;
            }
          });
          const hd = (
            (this.charts.hd /
              this.threshold.hardness_threshold[
                this.threshold.hardness_threshold.length - 1
              ].to) *
            100
          ).toFixed(2);
          const temp = (
            (this.charts.temp /
              this.threshold.temps_threshold[
                this.threshold.temps_threshold.length - 1
              ].to) *
            100
          ).toFixed(2);
          const ph = (
            (this.charts.ph /
              this.threshold.phs_threshold[
                this.threshold.phs_threshold.length - 1
              ].to) *
            100
          ).toFixed(2);
          this.rate = {
            temp: temp,
            ph: ph,
            hd: hd,
          };
          this.$nextTick(() => {
            setTimeout(() => {
              this.inittemp();
              this.initph();
              this.inithd();
            });
          });
          // if (res.data.data.rest === 0) {
          //       console.log("休息中");
          //       this.state = {
          //         name: "休息中",
          //         color: "red",
          //       };
          //     } else {
          //       console.log("生产中");
          //       this.state = {
          //         name: "生产中",
          //         color: "#66FD00",
          //       }; 
          //     }
          // return
          // const date = Date.parse(new Date());
          // let gapdate;
          // if (this.charts.temp_chart.length > 0) {
          //   gapdate =
          //     this.charts.temp_chart[this.charts.temp_chart.length - 1].ts;
          //   console.log("时间差", date, gapdate);
          //   if (gapdate != date) {
          //     if (date - gapdate > 1200000) {
          //       console.log("休息中");
          //       this.state = {
          //         name: "休息中",
          //         color: "red",
          //       };
          //     } else {
          //       console.log("生产中");
          //       this.state = {
          //         name: "生产中",
          //         color: "#66FD00",
          //       }; 
          //     }
          //   }
          // }
        });
    },
    factorydevicewashcost() {
      api
        .factorydevicewashcost({ device_code: this.device_code })
        .then((res) => {
          console.log("费用", res.data?.data);
          const arr = [];
          const arr1 = [];
          for (let i in res.data?.data.today) {
            console.log(i);
            arr.push(res.data?.data.today[i].toFixed(2));
          }
          for (let i in res.data?.data.week) {
            console.log(i);
            arr1.push(res.data?.data.week[i].toFixed(2));
          }
          console.log(arr, arr1);
          this.cost.today = arr;
          this.cost.week = arr1;
          console.log(this.cost);
          setTimeout(() => {
            this.initutilities();
          });
        });
    },
    factoryindicatorsdata() {
      api.factoryindicatorsdata({ code: this.factory.code }).then((res) => {
        console.log("指标阀值", res);
        this.threshold = res.data?.data;
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    videohttp(e) {
      const data = {
        id: this.charts.cctv[e].cctv_id,
      };
      api.videohttps(data).then((res) => {
        console.log("视频链接", res);
        this.videosrc = res.data.data?.videoUrl;
        console.log(this.videosrc);
        if (this.videosrc) {
          this.videoshow = !this.videoshow;
        } else {
          Notify({
            message: "视频连接超时请重试",
            duration: 1000,
          });
        }
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    onRefresh() {
      setTimeout(() => {
        this.factorytracedate();
        this.isLoading = false;
      }, 1000);
    },
    onRefreshday_list() {
      setTimeout(() => {
        this.factoryrfid();
        this.isLoading = false;
      }, 1000);
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        // 加载状态结束
        this.loading = false; // 数据全部加载完成
        if (this.history_list.length >= this.history_list.length) {
          this.finished = true;
        }
      }, 1000);
    },
    apptonelist() {
      const data = {
        app_name: "hasn",
      };
      api.apptonelist(data).then((res) => {
        console.log("语音", res?.data?.data);
        localStorage.setItem(
          "appToneList",
          JSON.stringify(res.data.data?.appToneList)
        );
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    backRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalIds) {
        clearInterval(this.intervalIds);
      } else {
        console.log(`打开定时器${this.backtime}ms 归零`);
        this.intervalIds = setInterval(() => {
          this.factorydevicewashsummary();
        }, this.backtime);
      }
    },
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId) {
        clearInterval(this.intervalId);
      } else {
        console.log(`打开定时器${this.datatime}`);
        this.intervalId = setInterval(() => {
          this.time();
          this.factoryindicatorsdata();
          this.factorydevicewashindicator();
        }, this.datatime);
      }
    },
    dataRefrehs() {
      // 计时器正在进行中，退出函数
      if (this.intervalId) {
        clearInterval(this.intervalId);
      } else {
        console.log(`打开定时器${this.datatimes}`);
        this.intervalId = setInterval(() => { }, this.datatimes);
      }
    },
    // 停止定时器
    clear() {
      console.log(`关闭定时器`);
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    clears() {
      clearInterval(this.intervalIds); //清除计时器
      this.intervalIds = null; //设置为null
    },
    videoshows() {
      this.videoshow = !this.videoshow;
    },
    inittemp() {
      console.log('this.charts.temp_chart', this.charts.temp_chart[0]);
      const temps_list = this.charts.temp_chart.map((i) => {
        return i.temperature;
      });
      const temp_date = this.charts.temp_chart.map((i) => {
        return i.time.split(" ")[1];
      });
      if (!this.temp_chart) {
        this.temp_chart = this.$echarts.init(document.getElementById("temp"));
      }
      this.temp_chart.setOption({
        grid: {
          width: "80%",
          height: "75%",
          y: 0,
        },
        tooltip: {
          trigger: "axis",
          tooltip: (e) => {
            return e.marker;
          },
        },
        dataZoom: {
          type: "inside",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: temp_date,
          axisLine: {
            lineStyle: {
              color: "#9c9c9c",
            },
          },
        },
        yAxis: {
          type: "value",
          min: function (value) {
            return parseInt(Math.max(0, value.min - 10));
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#808080",
            },
          },
          axisLabel: {
            formatter: (e) => {
              return e;
            },
          },
        },
        visualMap: {
          show: false,
          top: 0,
          right: 0,
          pieces: [
            {
              gt: 0, //gt（大于，greater than)
              lte: 60, //lte（小于等于 lettle than or equals）
              color: "red",
              label: "小于" + 60 + "异常",
            },
            {
              gt: 60,
              lte: 100,
              color: this.zccolor,
              label: "介于" + 60 + "~" + 100 + "正常",
            },
          ],
          outOfRange: {
            color: "#999",
          },
        },
        series: [
          {
            name: "当前温度",
            type: "line",
            data: temps_list,
            symbol: "none",
            smooth: "1",
            itemStyle: {
              width: 2,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: this.bjcolor, // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1989FA", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            markLine: {
              symbol: "none",
              data: [
                {
                  name: "预警线",
                  yAxis: 60,
                  lineStyle: {
                    color: "red",
                  },
                },
              ],
            },
          },
        ],
      });
    },
    initph() {
      const ph_list = this.charts.ph_chart.map((i) => {
        return Number(i.ph.toFixed(2));
      });
      const ph_date = this.charts.ph_chart.map((i) => {
        return i.time.split(" ")[1];
      });
      if (!this.ph_chart) {
        this.ph_chart = this.$echarts.init(document.getElementById("ph"));
      }
      this.ph_chart.setOption({
        grid: {
          width: "80%",
          height: "75%",
          y: 0,
        },
        tooltip: {
          trigger: "axis",
          tooltip: (e) => {
            return e.marker;
          },
        },
        dataZoom: {
          type: "inside",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ph_date,
          axisLine: {
            lineStyle: {
              color: "#9c9c9c",
            },
          },
        },
        yAxis: {
          type: "value",
          show: true,
          min: () => {
            return 3;
          },
          max: () => {
            return 9;
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#808080",
            },
          },
          axisLabel: {
            formatter: (e) => {
              return e;
            },
          },
        },
        visualMap: {
          show: false,
          top: 0,
          right: 0,
          pieces: [
            {
              gt: 0, //gt（大于，greater than)
              lte: 4, //lte（小于等于 lettle than or equals）
              color: "red",
            },
            {
              gt: 4,
              lte: 8.5,
              color: this.zccolor,
            },
            {
              gt: 8.5,
              color: "red",
            },
          ],
          outOfRange: {
            color: "#999",
          },
        },
        series: [
          {
            name: "当前pH值",
            type: "line",
            data: ph_list,
            symbol: "none",
            smooth: "1",
            itemStyle: {
              width: 2,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: this.bjcolor, // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1989FA", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            markLine: {
              symbol: "none",
              data: [
                {
                  name: "预警线",
                  yAxis: 8.5,
                  lineStyle: {
                    color: "red",
                  },
                },
                {
                  name: "预警线",
                  yAxis: 4,
                  lineStyle: {
                    color: "red",
                  },
                },
              ],
            },
          },
        ],
      });
    },
    inithd() {
      const hd_list = this.charts.hd_chart.map((i) => {
        return Number(i.hd.toFixed(2));
      });
      const hd_date = this.charts.hd_chart.map((i) => {
        return i.time.split(" ")[1];
      });
      if (!this.hd_chart) {
        this.hd_chart = this.$echarts.init(document.getElementById("hd"));
      }
      this.hd_chart.setOption({
        grid: {
          width: "80%",
          height: "75%",
          y: 0,
        },
        tooltip: {
          trigger: "axis",
          tooltip: (e) => {
            return e.marker;
          },
        },
        dataZoom: {
          type: "inside",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: hd_date,
          axisLine: {
            lineStyle: {
              color: "#808080",
            },
          },
        },
        yAxis: {
          type: "value",
          min: (value) => {
            return parseInt(Math.max(0, value.min - 20));
            // return value.min - 20;
          },
          max: (e) => {
            return parseInt(e.max + 20);
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#808080",
            },
          },
          axisLabel: {
            formatter: (e) => {
              return e;
            },
          },
        },
        visualMap: {
          show: false,
          top: 0,
          right: 0,
          pieces: [
            {
              gt: 0,
              lte: 25,
              color: this.zccolor,
            },
            {
              gt: 25,
              color: "red",
            },
          ],
          outOfRange: {
            color: "#999",
          },
        },
        series: [
          {
            name: "当前ppm值",
            type: "line",
            data: hd_list,
            symbol: "none",
            smooth: "1",
            itemStyle: {
              width: 2,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: this.bjcolor, // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1989FA", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            markLine: {
              symbol: "none",
              data: [
                {
                  name: "预警线",
                  yAxis: 25,
                  lineStyle: {
                    color: "red",
                  },
                },
              ],
            },
          },
        ],
      });
    },
    initchart() {
      this.chart = this.$echarts.init(document.getElementById("chart"));
      this.chart.setOption({
        grid: {
          width: "83%",
          height: "70%",
          x: 30,
          y: 5,
        },
        tooltip: {
          trigger: "axis",
          tooltip: (e) => {
            return e.marker;
          },
        },
        dataZoom: {
          type: "inside",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.daychart.date,
          axisLine: {
            lineStyle: {
              color: "#808080",
            },
          },
        },
        yAxis: {
          type: "value",
          min: (value) => {
            return parseInt(Math.max(0, value.min - 20));
            // return value.min - 20;
          },
          max: (e) => {
            return parseInt(e.max + 20);
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#808080",
            },
          },
          axisLabel: {
            formatter: (e) => {
              return e;
            },
          },
        },
        lineStyle: {
          color: this.zccolor, //改变折线颜色
        },
        series: [
          {
            name: "当前清洁重量",
            type: "line",
            data: this.daychart.we,
            symbol: "none",
            smooth: "1",
            itemStyle: {
              width: 2,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: this.bjcolor, // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1989FA", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
    },
    initclean() {
      this.clean = this.$echarts.init(document.getElementById("clean"));
      this.clean.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        series: [
          {
            name: "清洁程序",
            type: "pie",
            selectedMode: "single",
            radius: ["20%", "50%"],
            label: {
              position: "inner",
              fontSize: 14,
              color: "#fff",
            },
            labelLine: {
              show: false,
            },
            data: this.data.wash_techno_chart,
          },
          {
            name: "清洁程序",
            type: "pie",
            radius: ["60%", "80%"],
            labelLine: {
              show: false,
            },
            label: {
              position: "inner",
              fontSize: 10,
              color: "#fff",
            },
            data: this.data.programs_chart,
          },
        ],
      });
    },
    initutilities() {
      this.utilities = this.$echarts.init(document.getElementById("utilities"));
      this.utilities.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          containLabel: true,
          height: "80%",
          y: 10,
          x: 10,
        },
        xAxis: {
          type: "log",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: ["水", "电", "气"],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: "最近一天用量",
            type: "bar",
            label: {
              color: "#fff",
              show: true,
              position: "inside",
              formatter: (e) => {
                console.log("水电气", e);
                if (e.name == "电") {
                  return e.data + "千瓦时";
                } else {
                  return e.data + "吨";
                }
              },
            },
            data: this.cost.today,
          },
          {
            name: "最近一周平均用量",
            type: "bar",
            label: {
              color: "#fff",
              show: true,
              position: "inside",
              formatter: (e) => {
                console.log(e);
                return "七日平均";
              },
            },
            data: this.cost.week,
          },
        ],
      });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.dv-active-ring-chart .active-ring-info .active-ring-name {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.van-cell-group {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
/deep/.van-cell {
  padding: 0;
  font-size: 10px;
  height: 25.4px;
  background: rgba(0, 0, 0, 0);
  align-items: center;
  display: flex;
  justify-content: space-between;
}
/deep/.van-cell__title,
.van-cell__value {
  flex: none;
}
/deep/.van-cell::after {
  border-bottom: 1rem solid #f0f0f0;
}
/deep/[class*='van-hairline']::after {
  border: none;
}
/deep/.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-bottom: 0.3px solid #f8f8f8;
}
/deep/.dv-water-pond-level svg {
  margin-top: 0.02359rem;
  margin-left: 0.0609rem;
}
/deep/.dv-scroll-ranking-board .ranking-info {
  font-size: 7px;
  line-height: 7px;
}
/deep/.dv-scroll-ranking-board .ranking-column .inside-column {
  height: 5px;
}
/deep/.dv-scroll-ranking-board .ranking-column {
  margin-top: 2px;
}
/deep/.dv-capsule-chart .label-column {
  font-size: 6px;
}
/deep/.dv-capsule-chart .unit-label {
  font-size: 6px;
  height: 8px;
}
/deep/.dv-capsule-chart .capsule-item {
  height: 5px;
}
/deep/.dv-capsule-chart .capsule-item .capsule-item-column {
  margin-top: 0;
  height: 5px;
}
/deep/.dv-capsule-chart .capsule-item .capsule-item-column .capsule-item-value {
  font-size: 6px;
}
/deep/.dv-capsule-chart .unit-text {
  font-size: 6px;
}
/deep/.dv-water-pond-level text {
  font-size: 20px;
}
/deep/.dv-scroll-ranking-board .ranking-info .info-name {
  flex: none;
  padding-left: 45px;
}
/deep/.dv-digital-flop canvas {
  display: none;
}
.popup {
  width: 250px;
  height: 150px;
  // margin: 20px;
  padding: 20px;
  color: #40c5ff;
  background: rgba(7, 11, 23, 0.4);
}
.popuptitle {
  font-size: 20px;
}
.popupfooter {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 80%;
}
.vessels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vessel {
  width: 240px;
  height: 90px;
}
.cards {
  height: 75px;
}
.card {
  height: 75px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.reamk {
  color: rgb(74, 156, 189);
  font-size: 8px;
}
.bj {
  background-image: url('../assets/bj.jpg');
}
.logo {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 2px;
  margin-right: 3px;
}
.logos {
  width: 20px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
}
.info {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  height: 10px;
  margin-bottom: 2px;
}
.infotime {
  font-size: 12px;
  color: #fff;
  text-align: left;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.onheard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.heard {
  height: 16px;
  width: 16px;
}
.heardtitle {
  font-size: 16px;
  color: #fff;
}
.device {
  color: #fff;
  font-size: 6px;
  width: 25%;
  text-align: right;
  margin-right: 10px;
}
.flex {
  display: flex;
  align-items: flex-end;
  height: 210px;
  justify-content: center;
  margin-top: 30px;
}
.num {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nums {
  line-height: 15px;
  font-family: Verdana;
}
.left {
  position: absolute;
  width: 50%;
  height: 40%;
  left: 0;
  top: 0;
  font-size: 15px;
  color: #fff;
}
.right {
  position: absolute;
  width: 50%;
  height: 40%;
  right: 0;
  bottom: 0;
  font-size: 15px;
  color: #fff;
}
.left_bottom {
  position: absolute;
  left: 10px;
  bottom: 15px;
  font-size: 10px;
  color: #fff;
}
.reight_top {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 10px;
  color: #fff;
}
.centerright {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
}
.center {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 10px;
}
.numbers {
  font-size: 26px;
  color: #00c0ff;
  font-family: Verdana;
}
.rem {
  font-size: 10px;
}
.left_bottom_ban {
  display: flex;
  justify-content: space-around;
  position: relative;
}
.left_title {
  font-size: 8px;
  color: #fff;
  margin-bottom: 10px;
  position: absolute;
  bottom: 43%;
  left: 15%;
}
.left_num {
  color: #00c0ff;
  font-size: 25px;
  font-family: Verdana;
}
.left_pin {
  color: #00c0ff;
  font-size: 18px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left_fu {
  font-size: 8px;
  color: #fff;
}
.title {
  font-size: 12px;
  color: #00c0ff;
}
.img {
  width: 24px;
  height: 15px;
}
.chart {
  width: 280px;
  height: 85px;
}
.titleri {
  color: #00c0ff;
  font-size: 10px;
}
.yangshi {
  width: 90px;
  height: 15px;
}
.yuan {
  width: 100px;
  height: 100px;
}
.huan {
  width: 100px;
  height: 100px;
}
.borders {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cangwei {
  border-radius: 5px;
  width: 26px;
  background: rgba(4, 153, 209, 0.4);
  color: #39e4cd;
}
.cctv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #39e4cd;
}
.cctvimg {
  height: 100%;
  width: 100%;
  background: rgb(41 68 119 / 70%);
  border-radius: 10px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.label {
  color: #fff;
  font-size: 7px;
  line-height: 7px;
}
.gongjin {
  color: #0499d1;
  font-size: 12px;
  // line-height: 16px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.cangtime {
  font-size: 12px;
}
.clean {
  width: 140px;
  height: 110px;
}
.utilities {
  width: 140px;
  height: 115px;
}
.imgs {
  width: 105px;
  height: 65px;
  border-radius: 8px;
}
.cctvtitle {
  font-size: 6px;
  line-height: 10px;
}
.zong {
  color: #fff;
  position: absolute;
  bottom: 70%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 15px;
  font-family: Verdana;
}
.zongs {
  display: flex;
  align-items: flex-end;
}
.avatar {
  margin: 0 1px;
  background: #cccccc;
  width: 20px;
  border-radius: 3px;
  color: #f8f8f8;
  font-weight: bold;
  font-size: 15px;
  height: 20px;
}
</style>
